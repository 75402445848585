import { useCallback, useState } from 'react';

import { useQuery } from '@apollo/react-hooks';

import {
  lookupCustomerByCustomerId,
  lookupPrepaidByCardNumber,
  lookupOrderById,
  searchCardId,
  searchStores,
  SearchCustomersByPhoneNumber,
  CustomersByEmail,
  CustomerByLoyaltyId,
} from 'remote/queries';
import { EMAIL_REGEX, PHONE_NUMBER_REGEX, UUID_REGEX } from 'utils';

import { SearchResult } from './types';

export { searchResultDisplayMap } from './search-result-display-map';
export type { SearchResult };

export const useUniversalSearch = (
  searchTerm: string,
): {
  loading: boolean;
  hits: SearchResult[];
} => {
  const { data: prepaidByCardNumberData, loading: prepaidByCardNumberLoading } = useQuery(
    lookupPrepaidByCardNumber,
    {
      variables: { cardNumber: searchTerm.replace(/-/g, '') },
      skip: searchTerm.replace(/-/g, '').length !== 16 || EMAIL_REGEX.test(searchTerm),
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  );
  const { data: searchStoresData, loading: searchStoresLoading } = useQuery(searchStores, {
    variables: { searchTerm },
    skip: !searchTerm || EMAIL_REGEX.test(searchTerm),
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  const { data: searchByPhoneData, loading: searchByPhoneLoading } = useQuery(
    SearchCustomersByPhoneNumber,
    {
      variables: { phoneNumber: searchTerm },
      skip: !PHONE_NUMBER_REGEX.test(searchTerm),
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  );

  const { data: customersByEmailData, loading: customersByEmailLoading } = useQuery(
    CustomersByEmail,
    {
      variables: { email: searchTerm },
      skip: !EMAIL_REGEX.test(searchTerm),
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  );

  const { data: customerByCustomerIdData, loading: customerByCustomerIdLoading } = useQuery(
    lookupCustomerByCustomerId,
    {
      variables: { customerId: searchTerm },
      skip: !UUID_REGEX.test(searchTerm.trim()) || EMAIL_REGEX.test(searchTerm),
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  );

  const { data: customerByLoyaltyIdData, loading: customerByLoyaltyIdLoading } = useQuery(
    CustomerByLoyaltyId,
    {
      variables: { loyaltyId: searchTerm },
      skip: !UUID_REGEX.test(searchTerm.trim()),
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  );

  const { data: orderByIdData, loading: orderByIdLoading } = useQuery(lookupOrderById, {
    variables: { rbiOrderId: searchTerm },
    skip: !UUID_REGEX.test(searchTerm.trim()) || EMAIL_REGEX.test(searchTerm),
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  const { data: customerByCardId, loading: customerByCardIdLoading } = useQuery(searchCardId, {
    variables: { cardId: searchTerm.replace(/-/g, '') },
    // this is a TH specific feature
    // cardId is always 12 characters long and barcodes are never less than 85 chars
    skip:
      (searchTerm.length < 85 && searchTerm.replace(/-/g, '').length !== 12) ||
      EMAIL_REGEX.test(searchTerm),
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  const loading =
    customersByEmailLoading ||
    customerByCustomerIdLoading ||
    orderByIdLoading ||
    searchStoresLoading ||
    prepaidByCardNumberLoading ||
    customerByCardIdLoading ||
    searchByPhoneLoading ||
    customerByLoyaltyIdLoading;

  const hits = [
    customerByCustomerIdData?.Customer,
    ...(searchByPhoneData?.customersByPhoneNumber ?? []),
    ...(customersByEmailData?.customersByEmail ?? []),
    orderByIdData?.Order,
    orderByIdData?.Order?.customer,
    ...(searchStoresData?.stores ?? []),
    prepaidByCardNumberData?.prepaidByCardNumber,
    customerByCardId?.customerByRewardsCardId,
    customerByLoyaltyIdData?.customerByLoyaltyId,
  ].filter(Boolean);

  return { loading, hits };
};

export const useUniversalSearchFocus = (): {
  focused: boolean;
  setFocused: Function;
} => {
  const [focused, setFocused] = useState(false);
  const handleFocused = useCallback((isFocused) => {
    setFocused(isFocused);
  }, []);

  return { focused, setFocused: handleFocused };
};
